<div class="input-group">
	<tb-control-label [id]="id" [label]="label" [required]="required" [invalid]="invalid" />

  <input
    [id]="id"
    [type]="type"
    [placeholder]="_placeholder"
    [(ngModel)]="value"
    [disabled]="disabled"
    (input)="onChange(value)"
    (focus)="onTouched()"
    [required]="required"
    [readOnly]="readonly"
    pInputText
    class="w-full"
  />

  <div class="input-error-message">
		<ng-container *ngIf="invalid">
			<span *ngIf="control.errors?.['minlength'] else elseIf0">Min. {{ control.errors?.['minlength'].requiredLength || 3 }} Zeichen ({{ control.errors?.['minlength'].actualLength }})</span>

			<ng-template #elseIf0>
				<span *ngIf="control.errors?.['email'] else elseIf1">Ungültige E-Mail</span>

				<ng-template #elseIf1>
					<span *ngIf="control.errors?.['required'] else elseIf2">Pflichtfeld</span>

					<ng-template #elseIf2>
						<span *ngIf="control.errors?.['vatNumber'] else elseIf3">Ungültige Umsatzsteuer ID</span>
					</ng-template>

					<ng-template #elseIf3>
						<span *ngIf="control.errors?.['not_matching']">Eingaben Stimmen nicht überein</span>
					</ng-template>
				</ng-template>
			</ng-template>
		</ng-container>
  </div>
</div>
