import { Component, forwardRef, Input, ViewEncapsulation } from '@angular/core'
import { InputTextModule } from 'primeng/inputtext'
import { NgClass, NgIf, NgStyle } from '@angular/common'
import { FormsModule, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms'
import { DropdownModule } from 'primeng/dropdown'
import { BaseFormControlComponent } from '@app/components/base/base-form-control.component'
import { ControlLabelComponent } from '@app/components/uiux/control-label/control-label.component'

@Component({
  selector: 'tb-input',
  standalone: true,
  templateUrl: './input.component.html',
  encapsulation: ViewEncapsulation.None,
  imports: [
    InputTextModule,
    NgIf,
    ReactiveFormsModule,
    FormsModule,
    NgClass,
    NgStyle,
    DropdownModule,
    ControlLabelComponent,
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputComponent),
      multi: true
    },
  ],
})
export class InputComponent extends BaseFormControlComponent {

  @Input() type: string = 'text'

}
